<template>
  <div v-if="!$store.state.app.isLoadingPage">
    <v-row>
      <v-col
        cols="12"
        md="12"
        sm="12"
        order="1"
        class="align-self-end"
      >
        <h3>Dashboard</h3>
      </v-col>
      <!--<PayPal
        :amount="111"
        code-currency="USD"
        description="Test from dashboard"
        :credentials="{
          sandbox: 'AQ-YkuuofBH20cOFjh8iXKZm0WJSyV3McFpZSm8enKkaPREpoKL67yMHYimmpZipq5K-oXwLtZk1aj09',
          production: '',
        }"
      />-->

      <!--
        <v-col
          v-if="!isLoading"
          cols="12"
          md="12"
          sm="12"
        >
          <SIBS
            :amount="amount"
            currency="EUR"
            payment-type="DB"
            :transaction-i-d="transactionID"
            :form-context="formContext"
            :transaction-signature="transactionSignature"
          />
        </v-col>-->
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import store from '@/store'

// import PayPal from 'vue-paypal-checkout'
// eslint-disable-next-line import/no-unresolved
import PayPal from '@/views/payments/PayPal.vue'
// eslint-disable-next-line import/no-unresolved
import SIBS from '@/views/payments/SIBS.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    PayPal,
    // eslint-disable-next-line vue/no-unused-components
    SIBS,
  },
  data() {
    return {
      perfil: {},
      isLoading: true,
      idCheckout: null,
      transactionID: null,
      formContext: null,
      transactionSignature: null,
      url: null,
      token: null,
      clientID: null,
      terminalID: null,
      merchantTransactionId: null,
      transactionTimestamp: null,
      amount: 10.25,
    }
  },
  mounted() {
    this.perfil = this.$route.params.perfil
    this.cargaPerfil()

    this.$watch(
      () => this.$route.params,
      // eslint-disable-next-line no-unused-vars
      (toParams, previousParams) => {
        // store.state.app.cargandoMenu = true
        this.perfil = toParams.perfil

        // sessionStorage.setItem('perfil', toParams.perfil)
        // store.commit('getPerfilesAuth')
        // setTimeout(() => {
        //   store.state.app.cargandoMenu = false
        //   window.location.reload()
        // }, 800)
      },
    )
  },

  /* created() {
    this.getDataCheckouts()
  }, */
  methods: {
    cargaPerfil() {
      // console.log(store.state.app.userProfile)
      setTimeout(() => {
        store.state.app.isLoadingPage = false
      }, 800)
    },
    getCheckouts() {
      const json = {
        merchant: {
          terminalId: this.terminalID,
          channel: 'web',
          merchantTransactionId: this.merchantTransactionId,
        },
        transaction: {
          transactionTimestamp: this.transactionTimestamp,
          description: 'Transaction for order number 1',
          moto: false,
          paymentType: 'PURS',
          amount: {
            value: this.amount,
            currency: 'EUR',
          },
          paymentMethod: [
            'CARD', // REFERENCE, MBWAY, QRCODE
            'REFERENCE',
            'MBWAY',
            'QRCODE',
          ],
        },
      }
      this.axios
        .post(this.url, json, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
            'X-IBM-Client-Id': this.clientID,
          },
        })
        .then(res => {
          // transactionID, transactionSignature, formContext
          this.transactionID = res.data.transactionID
          sessionStorage.setItem('transactionID', this.transactionID)

          this.formContext = res.data.formContext
          this.transactionSignature = res.data.transactionSignature
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isLoading = false
        })
    },
    getDataCheckouts() {
      const json = {
        payments: 'sibs',
      }
      this.axios
        .post('sibs/checkouts', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success) {
            // console.log(res.data.data)
            this.url = res.data.data.url
            this.token = res.data.data.token
            this.clientID = res.data.data.clientID
            this.terminalID = parseInt(res.data.data.terminalID, 10)
            this.merchantTransactionId = res.data.data.merchantTransactionId
            this.transactionTimestamp = res.data.data.transactionTimestamp

            this.getCheckouts()
          }
        })
    },
  },
}
</script>
