<template>
  <v-row>
    <v-col
      cols="12"
      offset-md="4"
      md="4"
      sm="4"
    >
      <div ref="paypal"></div>
    </v-col>
  </v-row>
</template>

<script>
import { loadScript } from 'vue-plugin-load-script'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    amount: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    codeCurrency: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    description: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    credentials: {
      type: Object,
    },
  },
  data() {
    return {
      myStyle: {
        label: 'checkout',
        size: 'responsive', // small | medium | large | responsive
        shape: 'pill', // pill | rect
        color: 'gold', // gold | blue | silver | black
      },
    }
  },
  mounted() {
    loadScript('https://www.paypal.com/sdk/js?client-id=AQ-YkuuofBH20cOFjh8iXKZm0WJSyV3McFpZSm8enKkaPREpoKL67yMHYimmpZipq5K-oXwLtZk1aj09')
      .then(() => {
        // Script is loaded, do something
        window.paypal.Buttons({
          style: {
            layout: 'vertical', // horizontal | vertical
            color: 'white', // white | gold | blue | silver | black
            shape: 'pill', // pill | rect
            label: 'paypal',
          },

          createOrder: (data, action) => action.order.create({
            purchase_units: [
              {
                description: this.description,
                amount: {
                  currency_code: this.codeCurrency,
                  value: this.amount,
                },
              },
            ],
          }),
          onApprove: async (data, actions) => {
            const order = await actions.order.capture()
            if (order.status === 'COMPLETED') {
              // console.log('order', order.id)
              this.$toast.success(this.$t('msg.payConfirm'))
              this.$emit('payPayPal', order.id)
            }
          },
          onError: err => {
            console.log(err)
          },
        })
          .render(this.$refs.paypal)
      })
      .catch(() => {
      // Failed to fetch script
        console.log('No carga')
      })
  },
}
</script>
