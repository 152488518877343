<template>
  <v-row v-if="!isLoading">
    <v-col
      cols="12"
    >
      <!--
        spg-config="{formConfig}"
        spg-style="{formStyle}"
      -->
      <!--:spg-config="{
          'paymentMethodList': [],
          'amount': { 'value': 5, 'currency': 'EUR' },
          'language': 'en',
          'redirectUrl': 'https://www.pay.sibs.com/documentacao/sibs-gateway',
          'customerData': null}"-->
      <form
        class="paymentSPG"
        :spg-context="formContext"
        :spg-config="JSON.stringify(formConfig)"
      >
      </form>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { loadScript } from 'vue-plugin-load-script'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    amount: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    currency: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    paymentType: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    transactionID: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    formContext: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    transactionSignature: {
      type: String,
    },
  },
  data() {
    return {
      isLoading: false,
      formConfig: {
        paymentMethodList: [],
        amount: { value: this.amount, currency: this.currency },
        language: 'es',

        // redirectUrl: this.$router({ name: 'payments-success' }),
        redirectUrl: `${process.env.VUE_APP_URL}payments/success`,
        customerData: {
          cardName: 'Fer',
        },
      },
      formStyle: '',
    }
  },
  mounted() {
    loadScript(`https://api.sibspayments.com/assets/js/widget.js?id=${this.transactionID}`)
  },
  created() {
    document.getElementById('icardHolder').value = 'Fer'
  },
  methods: {},
}
</script>
